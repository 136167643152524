<template>
  <div id="app">
    <b-navbar
      fixed="top"
      variant="dark"
      type="dark"
    >
      <b-navbar-brand to="/">
        Home
      </b-navbar-brand>
      <b-navbar-nav>
        <b-nav-item to="/domains" :active="$route.name.indexOf('domain') > -1">
          Domains
        </b-nav-item>
        <b-nav-item :active="$route.name.indexOf('ip')>-1" to="/ipAddresses">
          IP Addresses
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav
        v-if="isLoggedIn"
        class="ml-auto"
      >
        <b-nav-item
          right
          @click="logout"
        >
          Logout
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <amplify-authenticator :handle-auth-state-change="test">
      <amplify-sign-in
        slot="sign-in"
        :hide-sign-up="true"
      />
      <router-view />
    </amplify-authenticator>
    <footer class="text-white fixed-bottom bg-dark">
      &copy; 2021 -
      <b-link
        href="https://caliburnsecurity.com/"
        target="_blank"
      >
        Caliburn Security
      </b-link>
    </footer>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

//TODO: Learn more about prettier linter
export default {
  name: "App",
  data() {
    return {
      isLoggedIn: false
    };
  },
  async created() {
    this.loginAuth = onAuthUIStateChange(authState => {
      if (authState === AuthState.SignedIn) {
        this.isLoggedIn = true;
      } else this.isLoggedIn = false;
    });
  },
  beforeDestroy() {
    this.loginAuth();
  },
  methods: {
    test() {
      console.log("TEST");
    },
    async logout() {
      //let user = await Auth.currentAuthenticatedUser();
      //console.log(user.signInUserSession.accessToken.payload["cognito:groups"]);
      try {
        await Auth.signOut();
        this.$router.push("/");
      } catch (error) {
        console.log(error.message);
      }
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 5rem;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
body {
  padding-bottom: 60px;
}
footer {
  padding: 0.25rem;
}
</style>
