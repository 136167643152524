<!-- eslint-disable -->
<template>
  <div>
    <h1>{{ ip }}</h1>
    <hr />
    <h2>Check information</h2>
    <b-container>
      <b-row>
        <b-col>
          <b-table
            striped
            hover
            bordered
            caption-top
            responsive
            fixed-header
            sort-icon-left
            :items="shodanOpenPorts"
            :fields="shodanOpenPortFields"
            :sort-by.sync="shodanPortSortBy"
            :busy="isBusy"
          >
            <template #table-caption>
              Checked open ports
            </template>
            <template #table-busy>
              <div class="text-center text-danger">
                <b-spinner class="align-middle">
                  <strong>Loading...</strong>
                </b-spinner>
              </div>
            </template>
            <template v-slot:head(service)="data">
              {{data.label}}
              <b-icon
                id="addDomainInfo"
                v-b-tooltip.hover
                class="mb-2 mr-sm-2 mb-sm-0"
                icon="question-circle-fill"
                variant="primary"
              />
              <b-tooltip
                target="addDomainInfo"
                triggers="hover"
                placement="right"
              >
                Using default config (ports 80 and 443) only.
              </b-tooltip>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <!-- <h2>Configuration &amp; health check results</h2> -->
          <b-button v-b-toggle.checkCollapse variant="info"
            >Show configuration &amp; Shodan results</b-button
          >
          <b-collapse id="checkCollapse">
              <b-row>
                  <b-col>
                      <p><strong>Configuration</strong></p>
                    <pre class="text-left">{{
                        JSON.stringify(config, undefined, 2)
                    }}</pre>
                  </b-col>
                  <b-col>
                    <p><strong>Open ports</strong></p>
                    <pre class="text-left">{{
                        JSON.stringify(shodanInfo, undefined, 2)
                    }}</pre>
                  </b-col>
              </b-row>

          </b-collapse>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "IpInfo",
  data() {
    return {
      ipInfo: null,
      isBusy: true,
      shodanInfo: "",
      shodanOpenPorts: [],
      config: "",
      ip: "",
      shodanOpenPortFields: [
        { key: "port", sortable: true, label: "Port", stickyColumn: true },
        { key: "service", sortable: true, label: "Service", stickyColumn: true}
      ],
      shodanPortSortBy: "port"
    };
  },
  async created() {
    (this.ip = this.$route.params.ip),
      (this.$http.defaults.headers.common = {
        "Content-Type": "application/json",
        "x-api-key": this.api_key
      }),
      this.getipInfo(this.ip);
  },

  methods: {
    async getipInfo(ip) {
      this.$http.get(`${this.ip_endpoint}/object/${ip}`).then(result => {
        let config = result.data.config;
        this.config = config;
        // TODO: Error handling if can't get information.
        let shodanResults = result.data.shodanInfo;
        this.shodanInfo = shodanResults;
        for(let portInfo of this.shodanInfo.openPorts) {
            this.shodanOpenPorts.push({port: portInfo.port, service: portInfo.service});
        }
      });
      this.isBusy = false;
    }
  }
};
</script>

<style scoped>
.tooltip { top: 0; }
</style>
