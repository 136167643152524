<template>
  <div v-if="showAll">
    <b-container fluid>
      <b-row>
        <b-col>
          <h1>IPv4 Monitor</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card title="IPv4s MONITORED">
            {{ monitored_ips }}
          </b-card>
        </b-col>
        <b-col>
          <b-card title="FAILED CHECKS">
            {{ failed_checks }}
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <b-form
              inline
              @submit="addIP"
            >
              <b-form-input
                v-model="addIPForm.ip"
                required
                class="mb-2 mr-sm-2 mb-sm-0"
                placeholder="IP Address"
              />
              <b-icon
                id="addIPInfo"
                v-b-tooltip.hover
                class="mb-2 mr-sm-2 mb-sm-0"
                icon="question-circle-fill"
                variant="primary"
              />
              <b-tooltip
                target="addIPInfo"
                triggers="hover"
              >
                Using Shodan open ports only.
              </b-tooltip>

              <b-button
                type="submit"
                variant="primary"
              >
                Add
              </b-button>
            </b-form>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            ref="table"
            striped
            :per-page="perPage"
            :current-page="currentPage"
            hover
            head-variant="light"
            responsive
            bordered
            sort-icon-left
            :items="IPs"
            :fields="ipFields"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-danger">
                <b-spinner class="align-middle">
                  <strong>Loading...</strong>
                </b-spinner>
              </div>
            </template>
            <template v-slot:cell(ipaddr)="{ value }">
              <b-icon
                class="float-left"
                icon="trash-fill"
                variant="secondary"
                @click="deleteIPModal(value)"
              />
              <router-link :to="{ name: 'ipAddresses', params: { ip: value } }">
                {{ value }}
              </router-link>
            </template>
            <template v-slot:cell(shodan)="{ value }">
              <div v-if="value == true">
                <b-icon
                  font-scale="1.5"
                  variant="success"
                  icon="check-circle-fill"
                />
              </div>
              <div v-if="value == false">
                <b-icon
                  font-scale="1.5"
                  variant="danger"
                  icon="x-circle-fill"
                />
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="rows"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="isAdmin()">
            <h3>Admin tools</h3>
            <b-button
              variant="danger"
              pill
              @click="deleteAllIPs()"
            >
              Delete all IP addresses
            </b-button>
            <b-button
              variant="primary"
              pill
              @click="refreshIPs()"
            >
              Refresh IP addresses
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <div v-else>
    <b-row class="text-left">
      <b-col>
        <h2>IPv4 Addresses</h2>
      </b-col>
    </b-row>
    <b-row>
        <b-col>
          <b-table
            ref="table"
            striped
            :per-page="perPage"
            :current-page="currentPage"
            hover
            head-variant="light"
            responsive
            bordered
            sort-icon-left
            :items="IPs"
            :fields="ipFields"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-danger">
                <b-spinner class="align-middle">
                  <strong>Loading...</strong>
                </b-spinner>
              </div>
            </template>
            <template v-slot:cell(ipaddr)="{ value }">
              <b-icon
                class="float-left"
                icon="trash-fill"
                variant="secondary"
                @click="deleteIPModal(value)"
              />
              <router-link :to="{ name: 'ipAddresses', params: { ip: value } }">
                {{ value }}
              </router-link>
            </template>
            <template v-slot:cell(shodan)="{ value }">
              <div v-if="value == true">
                <b-icon
                  font-scale="1.5"
                  variant="success"
                  icon="check-circle-fill"
                />
              </div>
              <div v-if="value == false">
                <b-icon
                  font-scale="1.5"
                  variant="danger"
                  icon="x-circle-fill"
                />
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="rows"
          />
        </b-col>
      </b-row>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
const isIp = require("is-ip");
export default {
  name: "IPSummary",
  props: {
    showAll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      monitored_ips: 0,
      failed_checks: 0,
      addIPForm: {
        ip: ""
      },
      isBusy: true,
      ipFields: [
        { key: "ipaddr", label: "IP Address", sortable: true },
        { key: "shodan", label: "Shodan Status", sortable: true }
      ],
      IPs: [],
      perPage: 10,
      currentPage: 1
    };
  },
  computed: {
    rows() {
      return this.IPs.length;
    }
  },
  async created() {
    (this.$http.defaults.headers.common = {
      "Content-Type": "application/json",
      "x-api-key": this.api_key
    }),
      this.getIPs();
  },
  methods: {
    deleteIPModal(ip) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete ${ip}?`, {
          okTitle: "Yes",
          okVariant: "danger",
          title: "Delete IP address?",
          centered: true
        })
        .then(ret => {
          if (ret === true) {
            this.deleteIp(ip);
          }
        });
    },
    async isAdmin() {
      let user = await Auth.currentAuthenticatedUser();
      return (
        "Admin" in user.signInUserSession.accessToken.payload["cognito:groups"]
      );
    },
    deleteAllIPs() {
      console.log("Deleting all IPs");
      for (let ip of this.IPs.map(x => x.ipaddr)) {
        this.deleteIp(ip);
      }
    },
    async addIP(event) {
      event.preventDefault();
      let ip = this.addIPForm.ip;
      let ips = this.IPs.map(i => i.ip);
      if (!ip) {
        console.log("No IP address provided");
        return;
      } else if (ips.includes(ip)) {
        console.log("Duplicate IP address identified");
        return;
      } else if (!isIp(ip)) {
        console.log("Invalid IP address");
        return;
      }
      console.log(`Attempting to add ${ip} for monitoring.`);
      this.$http
        .post(`${this.ip_endpoint}`, { ip: ip })
        .then(result => {
          console.log(result.status);
          this.refreshIPs();
          this.addIPForm.ip = "";
        })
        .catch(err => {
          console.log(err);
        });
    },
    async refreshIPs() {
      this.IPs = [];
      this.isBusy = true;
      await this.sleep(3000);
      this.getIPs();
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    deleteIp(ip) {
      this.isBusy = true;
      this.$http
        .delete(`${this.ip_endpoint}/object/${ip}`)
        .then(() => {
          // Should just remove from array...but yeah
          let newIPs = [];
          for (let iprecord of this.IPs) {
            if (iprecord.ipaddr != ip) {
              newIPs.push(iprecord);
            }
          }
          this.IPs = newIPs;
        })
        .catch(err => {
          console.log(`Could not delete ${ip}: ${err}`);
        });
      this.isBusy = false;
    },
    async getFailedChecks(){
      this.$http.get(`${this.ip_endpoint}`).then(result=>{
        let failed_checks = 0;
        result.data.data.forEach(shodanInfo => {
          if(!Object.prototype.hasOwnProperty.call(shodanInfo, "shodanInfo")) {
            failed_checks++;
          }
        });
        this.failed_checks = failed_checks;
      });
    },
    async getIPs() {
      this.$http
        .get(`${this.ip_endpoint}`)
        .then(result => {
          let configCheck = result.data.data.map(x => x.config);
          if (configCheck.includes(undefined)) {
            // Error, need to sleep and then re-run this.
            throw "DOMAIN_CONFIG_MISSING";
          }
          result.data.data.forEach(ipInfo => {
            let ip = ipInfo.ip;
            let config = ipInfo.config;
            let shodanOverall = false;

            if (config.shodan.openPorts === true) {
              // Check for open ports via Shodan is enabled
              if (
                ipInfo.shodanInfo &&
                ipInfo.shodanInfo.openPorts &&
                ipInfo.shodanInfo.openPorts != null
              ) {
                shodanOverall = true;
              }
            }

            this.IPs.push({
              ipaddr: ip,
              shodan: shodanOverall
            });
          });
          this.monitored_ips = this.IPs.length;
          this.$emit("childToParent", this.monitored_ips);
          this.getFailedChecks();
          this.isBusy = false;
        })
        .catch(err => {
          console.log(`ERROR: ${JSON.stringify(err)}`);
        });
    }
  }
};
</script>

<style scoped>
.row {
  margin-top: 1rem;
}
</style>
