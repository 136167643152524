<template>
  <div>
    <h1>{{ fqdn }}</h1>
    <hr>
    <h2>Check information</h2>
    <b-container>
      <b-row>
        <b-col>
          <b-table
            striped
            hover
            bordered
            caption-top
            responsive
            fixed-header
            sort-icon-left
            :items="checkOpenPorts"
            :fields="checkOpenPortFields"
            :sort-by.sync="checkPortSortBy"
            :busy="isBusy"
          >
            <template #table-caption>
              Checked open ports
            </template>
            <template #table-busy>
              <div class="text-center text-danger">
                <b-spinner class="align-middle">
                  <strong>Loading...</strong>
                </b-spinner>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col>
          <b-table
            :busy="isBusy"
            striped
            hover
            sort-icon-left
            bordered
            caption-top
            responsive
            fixed-header
            :sort-by.sync="checkWebStatusSortBy"
            :items="checkWebStatus"
            :fields="checkWebStatusFields"
          >
            <template #table-caption>
              Checked HTTP status
            </template>
            <template #table-busy>
              <div class="text-center text-danger">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <template v-slot:cell(httpStatus)="data">
              <div class="text-danger" v-if="isNaN(data.item.httpStatus)">
                {{data.item.httpStatus}}
              </div>
              <div v-else-if="data.item.ssl === true">
                <b-link target="_blank" :href="`https://${fqdn}:${data.item.port}`">
                  {{
                    data.item.httpStatus
                  }}
                </b-link>
              </div>
              <div v-else>
                <b-link
                  target="_blank"
                  :href="`http://${fqdn}:${data.item.port}`"
                >
                  {{ data.item.httpStatus }}
                </b-link>
              </div>
            </template>
            <!-- <template v-slot:cell(screenshot)="{value}">
              <ScreenshotService v-if="!value" url="google.com" />
            </template> -->
            <template v-slot:cell(ssl)="{ value }">
              <div v-if="value === true">
                <b-icon icon="lock-fill" />
              </div>
              <div v-else>
                <b-icon icon="unlock-fill" />
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            :busy="isBusy"
            striped
            hover
            sort-icon-left
            bordered
            caption-top
            responsive
            fixed-header
            :items="tlsCertificateItems"
            :fields="tlsCertificateFields"
          >
            <template #table-caption>
              TLS certificate information
            </template>
            <template #table-busy>
              <div class="text-center text-danger">
                <b-spinner class="align-middle">
                  <strong>Loading...</strong>
                </b-spinner>
              </div>
            </template>
            <template v-slot:cell(validFrom)="data">
              <div v-if="data.item.isExpired == true">
                <b-icon
                  icon="hourglass-bottom"
                  variant="danger"
                />
                <strong class="text-danger">{{ data.item.validFrom }}</strong>
              </div>
              <div v-else>
                {{ data.item.validFrom }}
              </div>
            </template>
            <template v-slot:cell(validTo)="data">
              <div v-if="data.item.isExpiringSoon == true">
                <b-icon
                  icon="hourglass-split"
                  variant="warning"
                />
                <strong class="text-warning">{{ data.item.validTo }}</strong>
              </div>
              <div v-else>
                {{ data.item.validTo }}
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            :busy="isBusy"
            striped
            hover
            sort-icon-left
            bordered
            caption-top
            responsive
            fixed-header
            :items="dnsItems"
            :fields="dnsFields"
          >
            <template #table-caption>
              DNS records
              <b-alert
                show
                variant="primary"
              >
                Only the following DNS record types are checked:
                <strong>MX, CNAME, A, AAAA, TXT, SOA</strong>
              </b-alert>
            </template>
            <template #table-busy>
              <div class="text-center text-danger">
                <b-spinner class="align-middle">
                  <strong>Loading...</strong>
                </b-spinner>
              </div>
            </template>
            <template v-slot:cell(recordValue)="{ value }">
              <div v-if="value == '&quot;ENODATA&quot;'">
                <pre class="text-danger text-left">{{
                  value.replace(/["]+/g, "")
                }}</pre>
              </div>
              <div v-else>
                <pre class="text-left">{{ value }}</pre>
              </div>
            </template>
          </b-table>
          <b-button
            v-b-toggle.dnsChangeCollapse
            variant="info"
          >
            Show DNS change log
          </b-button>
          <br>
          <b-collapse id="dnsChangeCollapse">
            <b-table
              :busy="isBusy"
              striped
              hover
              sort-icon-left
              bordered
              caption-top
              responsive
              fixed-header
              :items="dnsChangeItems"
              :fields="dnsChangeFields"
            >
              <template v-slot:cell(recordValue)="{ value }">
                <pre class="text-left">{{ value }}</pre>
              </template>
              <template v-slot:cell(changeType)="{ value }">
                <div
                  v-if="value == 'RECORD_CHANGED'"
                  class="text-warning"
                >
                  <strong>{{ value }}</strong>
                </div>
                <div
                  v-if="value == 'RECORD_REMOVED'"
                  class="text-danger"
                >
                  <strong>{{ value }}</strong>
                </div>
                <div
                  v-if="value == 'RECORD_ADDED'"
                  class="text-success"
                >
                  <strong>{{ value }}</strong>
                </div>
              </template>
            </b-table>
          </b-collapse>
        </b-col>
      </b-row>
      <hr>
      <!--       <b-row>
          <b-col>
            <ScreenshotService url="google.com" />
          </b-col>
      </b-row>
      <hr/> -->
      <b-row>
        <b-col>
          <!-- <h2>Configuration &amp; health check results</h2> -->
          <b-button
            v-b-toggle.checkCollapse
            variant="info"
          >
            Show configuration &amp; health check results
          </b-button>
          <b-collapse id="checkCollapse">
            <pre class="text-left">{{
              JSON.stringify(config, undefined, 2)
            }}</pre>
          </b-collapse>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "DomainInfo",
  /*   components: {
    ScreenshotService
  }, */

  data() {
    return {
      domainInfo: null,
      isBusy: true,
      config: "",
      fqdn: "",
      checkOpenPortFields: [
        { key: "port", sortable: true, label: "Port", stickyColumn: true },
        { key: "lastChecked", sortable: true, label: "Last Checked" } //Add a date formatter...
      ],
      checkWebStatusFields: [
        { key: "httpStatus", sortable: true, label: "HTTP Status" },
        { key: "ssl", sortable: true, label: "TLS" },
        { key: "port", sortable: true, label: "Port", stickyColumn: true },
        //{ key: "screenshot", label: "Screenshot"},
        { key: "lastChecked", sortable: true, label: "Last Checked" } //Add a date formatter...
      ],
      checkWebStatusSortBy: "httpStatus",
      checkPortSortBy: "port",
      checkOpenPorts: [],
      checkWebStatus: [],
      dnsItems: [],
      dnsFields: [
        {
          key: "recordType",
          sortable: true,
          label: "Record type",
          stickyColumn: true
        },
        { key: "recordValue", label: "Record value" }
      ],
      dnsChangeFields: [
        {
          key: "recordType",
          sortable: true,
          label: "Record type",
          stickyColumn: true
        },
        { key: "changeType", sortable: true, label: "Change type" },
        { key: "recordValue", label: "Value", sortable: false }
      ],
      dnsChangeItems: [],
      tlsCertificateItems: [],
      tlsCertificateFields: [
        { key: "CN", sortable: true, label: "CN", stickyColumn: true },
        { key: "port", sortable: true, label: "Port", stickyColumn: true },
        { key: "validFrom", sortable: true, label: "Valid from" },
        { key: "validTo", sortable: true, label: "Valid until" },
        { key: "subject", label: "Subject" },
        { key: "issuer", label: "Issuer" },
        { key: "misc", label: "More information" }
      ]
    };
  },
  async created() {
    (this.fqdn = this.$route.params.fqdn),
      (this.$http.defaults.headers.common = {
        "Content-Type": "application/json",
        "x-api-key": this.api_key
      }),
      this.getDomainInfo(this.fqdn);
  },

  methods: {
    async getDomainInfo(fqdn) {
      this.$http.get(`${this.domain_endpoint}/object/${fqdn}`).then(result => {
        let config = result.data.config;
        this.config = config;
        let changes = config.healthChecks.changes;
        for (let change of changes) {
          if (change.checkType == "dnsCheck") {
            let val;
            if (change.changeType == "RECORD_CHANGED") {
              val =
                JSON.stringify(change.oldRecord, undefined, 1) +
                JSON.stringify(change.newRecord, undefined, 1);
            } else if (change.changeType == "RECORD_REMOVED") {
              val = JSON.stringify(change.oldRecord);
            } else val = JSON.stringify(change.newRecord);
            this.dnsChangeItems.push({
              recordType: change.recordType,
              recordValue: val,
              changeType: change.changeType
            });
          }
        }

        for (let check of config.healthChecks.portChecks) {
          try {
            this.checkOpenPorts.push({
              port: check.port,
              lastChecked: new Date(check.latestCheck)
                .toISOString()
                .slice(0, 10),
              _rowVariant: check.lastStatus === true ? "success" : "danger"
            });
          } catch (err) {
            console.log(`Error: ${err}`);
          }
        }
        for (let check of config.healthChecks.dnsChecks) {
          try {
            this.dnsItems.push({
              recordType: check.recordType,
              recordValue: JSON.stringify(check.recordData, undefined, 1)
            });
          } catch (err) {
            console.log(`Error: ${err}`);
          }
        }
        for (let check of config.healthChecks.webChecks) {
          try {
            this.checkWebStatus.push({
              port: check.port,
              ssl: check.isTLS,
              tlsExpired: check.isTLS ? check.isTLSExpired : null,
              httpStatus: check.httpStatus
                ? check.httpStatus
                : "Unable to retrieve status",
              lastChecked: new Date(check.latestCheck)
                .toISOString()
                .slice(0, 10),
              _rowVariant:
                check.isTLSExpired === true
                  ? "danger"
                  : check.httpStatus != 200
                  ? "warning"
                  : "success"
            });
            if (check.isTLS) {
              let cert = check.latestCertificateInformation;
              this.tlsCertificateItems.push({
                // TODO: Issuer and subject should be done via a join, and should be using HTML for formatting.
                port: check.port,
                isExpired: check.isTLSExpired,
                isExpiringSoon:
                  (new Date(cert.validTo).getTime() -
                    new Date(cert.validFrom).getTime()) /
                    (1000 * 3600 * 24) <
                  14
                    ? true
                    : false,
                validFrom: new Date(cert.validFrom).toISOString().slice(0, 10),
                validTo: new Date(cert.validTo).toISOString().slice(0, 10),
                misc: cert.subjectAltName
                  ? `Subject alternative name: ${cert.subjectAltName}`
                  : "",
                issuer: `C: ${cert.issuer.C}; O: ${cert.issuer.O}; CN: ${cert.issuer.CN}`,
                subject: `ST: ${cert.subject.ST}; C: ${cert.subject.C}; L: ${cert.subject.L}; O: ${cert.subject.O}`,
                CN: cert.subject.CN
              });
            }
          } catch (err) {
            console.log(`Error: ${err}`);
          }
        }
      });
      this.isBusy = false;
    }
  }
};
</script>

<style scoped></style>
