/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b7a95f86-924b-4646-85f7-7f6eab576ede",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FfFMkhfjs",
    "aws_user_pools_web_client_id": "5cjkfn1sqr4flf870k3tcb1716",
    "oauth": {}
};


export default awsmobile;
