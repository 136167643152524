<template>
  <div v-if="showAll">
    <b-container fluid>
      <b-row>
        <b-col>
          <h1>Domains</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card title="DOMAINS MONITORED">
            {{ monitored_domains }}
          </b-card>
        </b-col>
        <b-col>
          <b-card title="FAILED CHECKS">
            {{ failed_checks }}
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <b-form
              inline
              @submit="addDomain"
            >
              <b-form-input
                v-model="addDomainForm.fqdn"
                required
                class="mb-2 mr-sm-2 mb-sm-0"
                placeholder="Domain"
              />
              <b-icon
                id="addDomainInfo"
                v-b-tooltip.hover
                class="mb-2 mr-sm-2 mb-sm-0"
                icon="question-circle-fill"
                variant="primary"
              />
              <b-tooltip
                target="addDomainInfo"
                triggers="hover"
              >
                Using default config (ports 80 and 443) only.
              </b-tooltip>

              <b-button
                type="submit"
                variant="primary"
              >
                Add
              </b-button>
            </b-form>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-alert
            variant="warning"
            dismissible
            show
          >
            <b-icon
              icon="exclamation-triangle-fill"
              variant="secondary"
            />
            Check status will usually <strong>not</strong> update properly when
            adding a new domain. You must refresh the page to see the latest
            results, or click directly into the domain.
            <b-icon
              icon="exclamation-triangle-fill"
              variant="secondary"
            />
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            ref="table"
            striped
            :per-page="perPage"
            :current-page="currentPage"
            hover
            head-variant="light"
            responsive
            bordered
            sort-icon-left
            :sort-by.sync="fqdnSortBy"
            :sort-desc.sync="fqdnSortDesc"
            :items="Domains"
            :fields="fqdnFields"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-danger">
                <b-spinner class="align-middle">
                  <strong>Loading...</strong>
                </b-spinner>
              </div>
            </template>
            <template v-slot:cell(fqdn)="{ value }">
              <b-icon
                class="float-left"
                icon="trash-fill"
                variant="secondary"
                @click="deleteDomainModal(value)"
              />
              <router-link :to="{ name: 'domain', params: { fqdn: value } }">
                {{ value }}
              </router-link>
            </template>
            <template v-slot:cell(dnsCheck)="{ value }">
              <div v-if="value == true">
                <b-icon
                  font-scale="1.5"
                  variant="success"
                  icon="check-circle-fill"
                />
              </div>
              <div v-if="value == false">
                <b-icon
                  font-scale="1.5"
                  variant="danger"
                  icon="x-circle-fill"
                />
              </div>
            </template>
            <template v-slot:cell(portCheck)="{ value }">
              <div v-if="value == true">
                <b-icon
                  font-scale="1.5"
                  variant="success"
                  icon="check-circle-fill"
                />
              </div>
              <div v-if="value == false">
                <b-icon
                  font-scale="1.5"
                  variant="danger"
                  icon="x-circle-fill"
                />
              </div>
            </template>
            <template v-slot:cell(webCheck)="{ value }">
              <div v-if="value == true">
                <b-icon
                  font-scale="1.5"
                  variant="success"
                  icon="check-circle-fill"
                />
              </div>
              <div v-if="value == false">
                <b-icon
                  font-scale="1.5"
                  variant="danger"
                  icon="x-circle-fill"
                />
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="rows"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="isAdmin()">
            <h3>Admin tools</h3>
            <b-button
              variant="danger"
              pill
              @click="deleteAllDomains()"
            >
              Delete all domains
            </b-button>
            <b-button
              variant="primary"
              pill
              @click="refreshDomains()"
            >
              Refresh domains
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <div v-else>
    <b-row class="text-left">
      <b-col>
        <h2>Domains</h2>
      </b-col></b-row>
<b-row>
        <b-col>
          <b-table
            ref="table"
            striped
            :per-page="perPage"
            :current-page="currentPage"
            hover
            head-variant="light"
            responsive
            bordered
            sort-icon-left
            :sort-by.sync="fqdnSortBy"
            :sort-desc.sync="fqdnSortDesc"
            :items="Domains"
            :fields="fqdnFields"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-danger">
                <b-spinner class="align-middle">
                  <strong>Loading...</strong>
                </b-spinner>
              </div>
            </template>
            <template v-slot:cell(fqdn)="{ value }">
              <b-icon
                class="float-left"
                icon="trash-fill"
                variant="secondary"
                @click="deleteDomainModal(value)"
              />
              <router-link :to="{ name: 'domain', params: { fqdn: value } }">
                {{ value }}
              </router-link>
            </template>
            <template v-slot:cell(dnsCheck)="{ value }">
              <div v-if="value == true">
                <b-icon
                  font-scale="1.5"
                  variant="success"
                  icon="check-circle-fill"
                />
              </div>
              <div v-if="value == false">
                <b-icon
                  font-scale="1.5"
                  variant="danger"
                  icon="x-circle-fill"
                />
              </div>
            </template>
            <template v-slot:cell(portCheck)="{ value }">
              <div v-if="value == true">
                <b-icon
                  font-scale="1.5"
                  variant="success"
                  icon="check-circle-fill"
                />
              </div>
              <div v-if="value == false">
                <b-icon
                  font-scale="1.5"
                  variant="danger"
                  icon="x-circle-fill"
                />
              </div>
            </template>
            <template v-slot:cell(webCheck)="{ value }">
              <div v-if="value == true">
                <b-icon
                  font-scale="1.5"
                  variant="success"
                  icon="check-circle-fill"
                />
              </div>
              <div v-if="value == false">
                <b-icon
                  font-scale="1.5"
                  variant="danger"
                  icon="x-circle-fill"
                />
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="rows"
          />
        </b-col>
      </b-row>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
  name: "DomainSummary",
  props: {
    showAll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isBusy: true,
      perPage: 10,
      addDomainForm: {
        fqdn: ""
      },
      currentPage: 1,
      Domains: [],
      monitored_domains: 0,
      failed_checks: 0,
      // Find a way to get this included in one common library instead of updating in every file...
      fqdnFields: [
        {
          key: "fqdn",
          sortable: true,
          label: "Domain",
          stickyColumn: true
        },
        { key: "portCheck", sortable: true, label: "Port Checks" },
        { key: "webCheck", sortable: true, label: "Web Checks" },
        { key: "dnsCheck", sortable: true, label: "DNS Checks" }
        //{ key: "config", sortable: false, label: "Configuration" }
      ],
      fqdnSortBy: "fqdn",
      fqdnSortDesc: false
    };
  },
  computed: {
    rows() {
      return this.Domains.length;
    }
  },
  async created() {
    (this.$http.defaults.headers.common = {
      "Content-Type": "application/json",
      "x-api-key": this.api_key
    }),
      this.getDomains()
    //this.getFailedChecks(); // TODO: This probably doesn't need to be a separate function - wasteful having two calls.
  },
  methods: {
    async isAdmin() {
      let user = await Auth.currentAuthenticatedUser();
      return (
        "Admin" in user.signInUserSession.accessToken.payload["cognito:groups"]
      );
    },
    deleteDomainModal(fqdn) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete ${fqdn}?`, {
          okTitle: "Yes",
          okVariant: "danger",
          title: "Delete domain?",
          centered: true
        })
        .then(ret => {
          if (ret === true) {
            this.deleteDomain(fqdn);
          }
        });
    },
    deleteAllDomains() {
      console.log("Deleting all domains");
      for (let fqdn of this.Domains.map(x => x.fqdn)) {
        this.deleteDomain(fqdn);
      }
    },
    deleteDomain(fqdn) {
      this.isBusy = true;
      this.$http
        .delete(`${this.domain_endpoint}/object/${fqdn}`)
        .then(() => {
          // Should just remove from array...but yeah
          let newDomains = [];
          for (let domain of this.Domains) {
            if (domain.fqdn != fqdn) {
              newDomains.push(domain);
            }
          }
          this.Domains = newDomains;
        })
        .catch(err => {
          console.log(`Could not delete ${fqdn}: ${err}`);
        });
      this.isBusy = false;
    },
    async getFailedChecks() {
      // BUG: THIS ISNT UPDATING PROPERLY
      // TODO: There should be a way to get this.Domains, but just getting Observer.  Ugh
      this.$http
        .get(`${this.domain_endpoint}`)
        .then(result => {
          let configCheck = result.data.data.map(x => x.config);
          if (configCheck.includes(undefined)) {
            // Error, need to sleep and then re-run this.
            throw "DOMAIN_CONFIG_MISSING";
          }
          let failed_checks = 0;
          result.data.data.forEach(domainInfo => {
            let config = domainInfo["config"]; //Assuming that ALL checks are present.
            for (let check of config.healthChecks.portChecks) {
              if (check.lastStatus === false) failed_checks++;
            }
            for (let check of config.healthChecks.webChecks) {
              if (check.lastStatus === false) failed_checks++;
            }
            for (let check of config.healthChecks.dnsChecks) {
              if (check.lastStatus === false) failed_checks++;
            }
          });
          this.failed_checks = failed_checks;
        })
        .catch(err => {
          if (err === "DOMAIN_CONFIG_MISSING") {
            this.sleep(100);
            this.getFailedChecks();
          }
        });
    },
    async addDomain(event) {
      event.preventDefault();
      let fqdn = this.addDomainForm.fqdn;
      let domains = this.Domains.map(domain => domain.fqdn);
      if (!fqdn) {
        console.log("No fqdn provided.");
        return;
      } else if (domains.includes(fqdn)) {
        console.log("Duplicate domain identified");
        return;
      } else if (
        !/(?=^.{4,253}\.?$)(^((?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,63}\.?$)/.test(
          fqdn
        )
      ) {
        //https://stackoverflow.com/questions/6603015/check-whether-a-string-matches-a-regex-in-js
        console.log("Definitely an invalid TLD.");
        return;
      }
      console.log(`Attempting to add ${fqdn} for monitoring.`);
      this.$http
        .post(`${this.domain_endpoint}`, {
          fqdn: fqdn
        })
        .then(result => {
          console.log(result.status);
          this.refreshDomains();
          this.addDomainForm.fqdn = "";
        });
    },
    async refreshDomains() {
      this.Domains = [];
      this.isBusy = true;
      await this.sleep(3000);
      this.getDomains();
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async getDomains() {
      this.$http
        .get(`${this.domain_endpoint}`)
        .then(result => {
          //console.log(result.data.data);

          let configCheck = result.data.data.map(x => x.config);
          if (configCheck.includes(undefined)) {
            // Error, need to sleep and then re-run this.
            throw "DOMAIN_CONFIG_MISSING";
          }

          result.data.data.forEach(domainInfo => {
            let fqdn = domainInfo["fqdn"];
            let config = domainInfo["config"];
            let portCheckOverall;
            let webCheckOverall;
            let dnsCheckOverall;
            if (config.healthChecks.portChecks) {
              //TODO: Find a way to show if some succeeded, and some failed
              for (let check of config.healthChecks.portChecks) {
                if (check.lastStatus === true && portCheckOverall !== false)
                  portCheckOverall = true;
                else portCheckOverall = false;
              }
            }
            if (config.healthChecks.webChecks) {
              for (let check of config.healthChecks.webChecks) {
                if (check.lastStatus === true && portCheckOverall !== false)
                  webCheckOverall = true;
                else webCheckOverall = false;
              }
            }
            if (config.healthChecks.dnsChecks) {
              for (let check of config.healthChecks.dnsChecks) {
                if (
                  dnsCheckOverall !== false &&
                  Object.prototype.hasOwnProperty.call(check, "recordData") &&
                  check.recordData !== "ENOTFOUND"
                )
                  dnsCheckOverall = true;
                else dnsCheckOverall = false;
              }
            }
            //TODO: Show the full JSON
            //Show configuration in general...icons, whatever

            this.Domains.push({
              fqdn: fqdn,
              config: config,
              portCheck: portCheckOverall,
              webCheck: webCheckOverall,
              dnsCheck: dnsCheckOverall
            });
          });
          this.monitored_domains = result.data.data.length;
          this.$emit("childToParent", this.monitored_domains);
          this.getFailedChecks();
          this.isBusy = false;
        })
        .catch(err => {
          // Need better error handling, use an enum?
          if (err === "DOMAIN_CONFIG_MISSING") {
            this.sleep(100);
            this.getDomains();
          }
        });
    }
  }
};
</script>

<style scoped>
.row {
  margin-top: 1rem;
}
</style>
