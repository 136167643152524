import Vue from "vue";
import App from "./App.vue";
import axios from "axios";

// Amplify configuration
import Amplify, * as AmplifyModules from "aws-amplify";
//import { AmplifyPlugin } from 'aws-amplify-vue';
import aws_exports from "./aws-exports";
Amplify.configure(aws_exports);

import "@aws-amplify/ui-vue";

/* import {
  applyPolyfills,
  defineCustomElements
} from '@aws-amplify/ui-components/loader';

applyPolyfills().then(()=>{
  defineCustomElements(window);
}); */

Vue.use(AmplifyModules);

// Bootstrap configuration
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Font awesome
// TOOD: Remove this in favor of bootstrap icons once I figure out the html issue
//import { library } from '@fortawesome/fontawesome-svg-core'
//import { faCoffee } from '@fortawesome/free-solid-svg-icons'
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//library.add(faCoffee)

//Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.prototype.$http = axios;

Vue.prototype.domain_endpoint =
  "https://24wjrgdbh2.execute-api.us-east-1.amazonaws.com/prod/domains";
Vue.prototype.ip_endpoint =
  "https://24wjrgdbh2.execute-api.us-east-1.amazonaws.com/prod/ips";
Vue.prototype.api_key = "HzgqIKySyC2nEV3iNfJKG7QMTq5p5lVg1HJvRZPv";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.config.productionTip = false;

import router from "./router";

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
