import Vue from "vue";
import Router from "vue-router";
import DomainInfo from "@/components/DomainInfo";
import DomainSummary from "@/components/DomainSummary";
import IPSummary from "@/components/IPSummary";
import IPInfo from "@/components/IPInfo";
import Home from "@/components/Home"

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/domains",
      name: "domains",
      component: DomainSummary
    },
    {
      path: "/domains/:fqdn",
      name: "domain",
      component: DomainInfo
    },
    {
      path: "/ipAddresses",
      name: "ipAddresses",
      component: IPSummary
    },
    {
      path: "/ipAddresses/:ip",
      name: "ipAddress",
      component: IPInfo
    }
  ]
});
