<template>
    <div>
        <b-container fluid>
            <b-row>
                <b-col>
                    <h1>Domain &amp; IPv4 Change Monitor</h1>
                </b-col>
            </b-row>
            <hr/>
            <b-row>
                <b-col>
                    <b-card title="DOMAINS MONITORED">
                        {{ monitored_domains }}
                        <template #footer>
                            <div class="text-right">
                                <b-link to="/domains">
                                    View domains
                                </b-link>
                            </div>
                        </template>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card title="IPv4s MONITORED">
                        {{ monitored_ips }}
                        <template #footer>
                            <div class="text-right">
                                <b-link to="/ipAddresses">
                                    View IPs
                                </b-link>
                            </div>
                        </template>                        
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <DomainSummary v-on:childToParent="onDomainRefresh" :showAll="false" ref="domainSummary"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <IPSummary v-on:childToParent="onIPRefresh" :showAll="false" />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import DomainSummary from "@/components/DomainSummary";
import IPSummary from "@/components/IPSummary";
export default {
    name: "Home",
    data() {
        return {
            monitored_domains: 0,
            monitored_ips: 0
        }
    },
    components: {
        DomainSummary,
        IPSummary
    },
    async created(){
        (this.$http.defaults.headers.common = {
        "Content-Type": "application/json",
        "x-api-key": this.api_key
        })
    },
    async mounted(){
        console.log(this.$refs.domainSummary)
    },
    methods: {
        onDomainRefresh(r){
            this.monitored_domains = r;
        },
        onIPRefresh(r){
            this.monitored_ips = r;
        }
    }
}
</script>

<style scoped>

</style>